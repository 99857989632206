import {
	VALIDATE_CUSTOMER,
	REGISTER_CUSTOMER,
} from '@/config/endpoint';
import { apiFilters } from '@/config/axios';
import Req from './AxiosRequest';

class RegisterAsCustomer {
	constructor() {
		this.data = new Req(apiFilters);
		this.customDataObject = {};
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.pagination = this.data.pagination;
	}

	clear() {
		this.data.clear();
	}

	validateCustomer(payload) {
		const { method, endpoint } = VALIDATE_CUSTOMER;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	registerCustomer(payload) {
		const { method, endpoint } = REGISTER_CUSTOMER;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default RegisterAsCustomer;
