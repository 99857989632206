<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<is-loading
			id="loading-pass"
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="true" />
		<div v-show="!loading">
			<div
				v-if="step === 'email'"
				class="class">
				<div class="col">
					<form
						@submit.prevent="validateCustomer"
						@keydown="clearError($event.target.name);"
						@change="clearError($event.target.name);">
						<input-text
							id="email"
							:label="translate('email')"
							:setter-value="email"
							:errors="errors['email']"
							:required="true"
							:type="'prepend_text'"
							icon="icon-envelope"
							@dataChanged="email = $event" />
						<button
							class="btn btn-primary btn-block">
							{{ translate('validate_guest') }}
						</button>
					</form>
				</div>
			</div>

			<div v-if="step === 'credentials'">
				<form
					@submit.prevent="setPassword"
					@keydown="clearError($event.target.name);"
					@change="clearError($event.target.name);">
					<b-input-group
						v-if="countries.length > 1"
						id="country"
						class="mb-3">
						<b-input-group-prepend>
							<b-input-group-text>
								<i class="icon-flag" />
							</b-input-group-text>
						</b-input-group-prepend>
						<div
							:class="typeof errors.country !== 'undefined' ? 'is-invalid' : ''"
							class="form-control"
							style="padding: 0px">
							<select
								id="country"
								v-model="selectedCountry"
								type="text"
								name="country"
								class="form-control"
								style="border: 0px; height: 100%;">
								<option :value="null">
									{{ translate('country') }}
								</option>
								<option
									v-for="country in countries"
									:key="country"
									:value="country">
									{{ translate(country.toLowerCase()) }}
								</option>
							</select>
						</div>
						<template v-if="typeof errors.country !== 'undefined'">
							<span
								v-for="error in errors['country']"
								:key="error"
								class="invalid-feedback animated fadeIn"
								v-text="error" />
						</template>
					</b-input-group>

					<input-text
						id="username"
						:label="translate('username')"
						:setter-value="username"
						:errors="errors['username']"
						:required="true"
						:type="'prepend_text'"
						icon="icon-user"
						@dataChanged="username = $event" />

					<input-text
						id="new_password"
						:label="translate('new_password')"
						:setter-value="new_password"
						:errors="errors['password']"
						:required="true"
						:type="'prepend_password'"
						icon="icon-lock"
						@dataChanged="new_password = $event" />

					<input-text
						id="new_password_confirmation"
						:label="translate('new_password_confirmation')"
						:setter-value="new_password_confirmation"
						:errors="errors['password_confirmation']"
						:required="true"
						:type="'prepend_password'"
						icon="icon-lock"
						@dataChanged="new_password_confirmation = $event" />

					<button
						:disabled="Object.keys(errors).length > 0"
						class="btn btn-primary btn-block">
						{{ translate('register_customer_password') }}
					</button>
				</form>
			</div>
		</div>
		<!-- <custom-modal
			:show.sync="showPromoModal"
			:close-text="translate('close')"
			ref-name="promoModal"
			:title="translate('customer_promo_title')"
			:modal-size="['xs', 'sm'].includes(windowWidth) ? 'xl' : 'lg-custom-2'">
			<template #sub-title>
				<hr
					:class="{
						'mx-3': ['xs', 'sm'].includes(windowWidth),
						'mx-6': !['xs', 'sm'].includes(windowWidth),
					}"
					class="my-3 custom-hr">
				<h5
					class="promo-sub-title-small"
					v-html="translate('customer_promo_sub_title_2')" />
				<hr
					:class="{
						'mx-3': ['xs', 'sm'].includes(windowWidth),
						'mx-6': !['xs', 'sm'].includes(windowWidth),
					}"
					class="my-3 custom-hr">
				<h3 class="font-weight-bold my-2">
					{{ translate('customer_promo_sub_title_1') }}
				</h3>
				<h5
					class="promo-sub-title-small"
					v-html="translate('customer_promo_sub_title_3')" />
			</template>
			<div class="text-center px-3">
				<h5 class="text-secondary-dark mb-3">
					{{ translate('customer_promo_body_1') }}
				</h5>
				<span
					class="text-secondary-dark"
					v-html="translate('customer_promo_body_2')" />
			</div>
			<template #footer>
				<div class="text-center text-secondary-dark font-weight-bold mt-4 pt-2 pb-4">
					<div class="mb-2">
						<img
							class="mr-2"
							width="20px"
							:src="require(`@/assets/images/themes/${themeName}/facebook-icon-2x.png`)">
						{{ facebook }}
					</div>
					<div>
						<img
							class="mr-2"
							width="20px"
							:src="require(`@/assets/images/themes/${themeName}/instagram-icon-2x.png`)">
						{{ instagram }}
					</div>
				</div>
			</template>
		</custom-modal> -->
	</div>
</template>
<script>
// Components
import InputText from '@/components/InputText';
import isLoading from '@/components/Loading';
// import CustomModal from '@/components/CustomModal';

// Mixins
import Captcha from '@/mixins/Captcha';
import Common from '@/mixins/Common';
import WindowSizes from '@/mixins/WindowSizes';

// Settings
import { DEFAULT_COUNTRY as defaultCountry } from '@/settings/Country';
import { COMPANY_FACEBOOK, COMPANY_INSTAGRAM } from '@/settings/General';

// Classes
import RegisterAsCustomer from '@/util/RegisterAsCustomer';
import DefinePermissions from '@/util/DefinePermissions';

// Translations
import {
	Validations, Auth, Countries, Profile, CustomerPromoModal,
} from '@/translations';

export default {
	name: 'RegisterAsCustomer',
	components: {
		InputText,
		isLoading,
		// CustomModal,
	},
	mixins: [Captcha, Common, WindowSizes],
	messages: [Validations, Auth, Countries, Profile, CustomerPromoModal],
	data() {
		return {
			countries: [],
			email: '',
			username: '',
			new_password: '',
			new_password_confirmation: '',
			selectedCountry: null,
			step: 'email',
			registerAsCustomer: new RegisterAsCustomer(),
			alert: new this.$Alert(),
			loading: false,
			// showPromoModal: false,
			themeName: process.env.VUE_APP_THEME,
			facebook: COMPANY_FACEBOOK,
			instagram: COMPANY_INSTAGRAM,
			registerCustomerResponse: null,
		};
	},
	computed: {
		errors() {
			try {
				return this.registerAsCustomer.errors.errors;
			} catch (err) {
				return {};
			}
		},
	},
	watch: {
		// showPromoModal(show) {
		// 	if (!show) {
		// 		this.handleLogin(this.registerCustomerResponse);
		// 	}
		// },
	},
	methods: {
		validateCustomer() {
			const payload = { email: this.email };
			this.getCaptchaToken('register_as_customer').then((response) => {
				payload['g-recaptcha-response'] = response;
				this.registerAsCustomer.validateCustomer(payload).then((validateResponse) => {
					this.countries = validateResponse.response.countries;
					this.selectedCountry = this.countries.length === 1 ? this.countries[0] : null;
					this.step = 'credentials';
				}).catch(() => {
					this.email = payload.email;
				}).finally(() => {});
			});
		},
		setPassword() {
			this.loading = true;
			const payload = {
				country: this.selectedCountry,
				email: this.email,
				username: this.username,
				password: this.new_password,
				password_confirmation: this.new_password_confirmation,
			};
			this.getCaptchaToken('register_as_customer').then((response) => {
				payload['g-recaptcha-response'] = response;
				this.registerAsCustomer.registerCustomer(payload).then((registerCustomerResponse) => {
					this.alert.toast('success', this.translate('registered_customer_success'), {
						position: 'top-end',
						showConfirmButton: false,
						timer: 8000,
					});
					this.registerCustomerResponse = registerCustomerResponse;
					this.handleLogin(this.registerCustomerResponse);
					// this.showPromoModal = true;
				}).catch(() => {
				}).finally(() => { this.loading = false; });
			});
		},
		clearError(field) {
			if (field) {
				if (Object.keys(this.errors).length) {
					if (field === 'new_password') {
						delete this.registerAsCustomer.errors.errors.password;
					}
					delete this.registerAsCustomer.errors.errors[field];
					this.registerAsCustomer.errors.errors = { ...this.registerAsCustomer.errors.errors };
				}
			}
		},
		handleLogin(registerCustomerResponse) {
			this.$user.handleLogin(registerCustomerResponse.response.token, {}).then(() => {
				try {
					this.country = this.$user.details().country.iso_code_2;
					this.setStoredCountry(this.$user.details().country.iso_code_2);
				} catch (error) {
					this.country = defaultCountry;
					this.setStoredCountry(defaultCountry);
				}
				this.$forceUpdate();
				const { rules } = DefinePermissions(this.$user.details().permissions);
				this.$ability.update(rules);
				this.$router.push({ name: 'MainHome' });
			});
		},
	},
};
</script>
